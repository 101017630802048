
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getAssistantsOfEvent,
  getAssistantsExcel,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import { userYear } from '@/helpers/date'
import { idoDateToDateAndHours } from '@/helpers/date'
import _ from 'lodash'

@Component({
  components: {
    SellerHeader,
  },
})
export default class Assistants extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')
  public filter = ''
  public filterByID = ''
  public totalPages = 1
  public page = 1

  public assistants = []
  public assistantsFiltered = []
  public skeletonCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public loading = false
  public custom_field_1: string = localStorage.getItem('custom_field_1')
  public custom_field_2: string = localStorage.getItem('custom_field_2')
  public custom_field_3: string = localStorage.getItem('custom_field_3')

  public verified = 'all'

  async getAssistantsOfEvent(page?, query?, verified?) {
    try {
      if (!query) this.loading = true
      const { data, link, meta } = await getAssistantsOfEvent(
        this.eventId,
        page,
        100,
        query,
        verified
      )
      this.totalPages = Math.ceil(meta.total / 100)
      this.assistants = data
      this.assistantsFiltered = _.orderBy(this.assistants, 'created_at', 'desc')
      this.loading = false
      if (verified) this.verified = verified
    } catch (e) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  formatDate(isoDate: string) {
    return idoDateToDateAndHours(isoDate)
  }

  getUserAge(date: Date) {
    return userYear(date)
  }

  getUserGender(gender: string) {
    if (gender == 'male') {
      return 'Masculino'
    } else if (gender == 'female') {
      return 'Femenino'
    } else {
      return 'Otro'
    }
  }

  searchAssistant() {
    const assistantsData = this.assistants.filter(
      assistant =>
        assistant.id.toString().includes(this.filterByID.toString()) &&
        assistant.fullname.toLowerCase().includes(this.filter.toLowerCase())
    )
    this.assistantsFiltered = _.orderBy(assistantsData, 'created_at', 'desc')
  }

  @Watch('page', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPageChange(nextPage: number, _currentPage: unknown) {
    this.getAssistantsOfEvent(nextPage, this.filter, this.verified)
  }

  async getAssistantsExcel() {
    const loader = Vue.$loading.show()

    const response = await getAssistantsExcel(this.eventId)

    loader.hide()

    return response
  }

  mounted() {}
}
